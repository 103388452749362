<script>
import layoutMixin from "@/mixins/layoutMixin";

export default {
  name: "SectionElement",
  mixins: [layoutMixin],
  props: {
    text: String,
    icon: Array,
    showIcon: { type: Boolean, default: true },
    showText: { type: Boolean, default: true },
  },
  data() {
    return {
      fontSize: "15px",
    };
  },
  render: function(createElement) {
    var sectionsStyle = {};
    if (this.layout.background) {
      switch (this.layout.background.type) {
        case 1:
          sectionsStyle.backgroundColor = this.layout.background.param;
          break;
        case 2:
          sectionsStyle.backgroundImage = `url(${this.layout.background.param})`;
          sectionsStyle.backgroundRepeat = "no-repeat";
          sectionsStyle.backgroundSize = "100% 100%";
          sectionsStyle.backgroundPosition = "center center";
          break;
      }
    }

    var renderedIcon = document.getElementById("icon-rendered");
    var iconHtml = renderedIcon.innerHTML;

    var innerHTML = "";
    if (this.showIcon && this.icon && this.icon.length === 2) {
      innerHTML += iconHtml;
    }
    if (this.showText && this.text && this.text.length > 0) {
      innerHTML += `<span class="ml-4">${this.text}</span>`;
    }

    this.adaptFont(innerHTML);

    // text class
    var align = "";
    var vAlign = "";
    var txtClass = "overflow-hidden overflow-clip flex flex-row";
    if (this.layout) {
      switch (this.layout.align) {
        case 0:
          align += "justify-start text-left";
          break;
        case 1:
          align += "justify-center text-center";
          break;
        case 2:
          align += "justify-end text-right";
          break;
      }
      switch (this.layout.vAlign) {
        case 0:
          vAlign += "items-start";
          break;
        case 1:
          vAlign += "items-center";
          break;
        case 2:
          vAlign += "items-end";
          break;
      }
    }

    var children = [];

    if (this.showIcon && this.icon) {
      children.push(
        createElement("font-awesome-icon", {
          props: {
            icon: this.icon,
          },
        })
      );
    }

    if (this.showText && this.text && this.text.length > 0) {
      children.push(
        createElement("span", {
          class: "ml-2",
        }),
        this.text
      );
    }

    return createElement(
      "div",
      {
        class: [
          this.layoutClass,
          this.bgClass,
          txtClass,
          this.customClass,
          vAlign,
          align,
        ],
        style: [
          this.layoutStyle,
          this.bgStyle,
          this.textStyle,
          sectionsStyle,
          this.customStyle,
        ],
      },
      children
    );
  },
};
</script>
